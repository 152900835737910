<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title> Konfiguracja </v-card-title>

        <v-alert v-if="message.content" :type="message.type">
          {{ message.content }}
        </v-alert>

        <v-row align="center" justify="center">
          <v-col cols="8" sm="8" md="6">
            <v-form ref="formPre" v-model="validPre" lazy="true">
              <v-col cols="12">
                Sposób rozliczań pieniędzy z pobrań
                <v-radio-group v-model="meta.inadvance_method">
                  <v-radio label="Do ręki" value="1" color="amber"></v-radio>
                  <v-radio label="Na konto" value="2" color="amber"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" v-if="parseInt(meta.inadvance_method) == 2">
                <v-text-field
                  v-model="meta.bank_account"
                  :rules="bankName"
                  label="nr konta"
                  maxlength="32"
                  name="account"
                  type="text"
                />
              </v-col>

              <v-col cols="12">
                Warunek do wypłaty pieniędzy z pobrań
                <v-radio-group v-model="meta.inadvance_type">
                  <v-radio label="Czas" value="1" color="amber"></v-radio>
                  <v-radio label="Kwota" value="2" color="amber"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" v-if="parseInt(meta.inadvance_type) == 1">
                <v-select
                  :items="['Tydzień', 'Miesiąc', 'Kwartał']"
                  label="Okres rozliczeń"
                  :rules="required"
                  v-model="meta.inadvance_limit"
                ></v-select>
              </v-col>

              <v-col cols="12" v-if="parseInt(meta.inadvance_type) == 2">
                <v-text-field
                  v-model="meta.inadvance_limit"
                  :rules="required"
                  label="Kwota"
                  name="amount"
                  suffix="zł"
                  type="number"
                />
              </v-col>

              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-btn
                    @click="save()"
                    :disabled="!validPre"
                    color="amber accent-3"
                    class="grey--text text--darken-4"
                    :x-large="true"
                    v-tilt
                    >Zapisz</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    data: () => ({
      validPre: false,
      message: {
        content: null,
        type: 'error',
      },
      meta: {
        bank_account: '',
        inadvance_limit: '',
        inadvance_method: '',
        inadvance_type: '',
      },
      bankName: [
        v => !!v || 'Pole jest wymagane',
        v => /[0-9 ]{26}/.test(v) || 'Wprowadź numer konta',
      ],
      required: [v => !!v || 'Pole jest wymagane'],
    }),
    watch: {
      'meta.bank_account': function (val) {
        if (!isNaN(parseInt(val.substr(val.length - 1, 1)))) {
          if (val.length == 2) this.meta.bank_account += ' ';
          else if (val.length == 7) this.meta.bank_account += ' ';
          else if (val.length == 12) this.meta.bank_account += ' ';
          else if (val.length == 17) this.meta.bank_account += ' ';
          else if (val.length == 22) this.meta.bank_account += ' ';
          else if (val.length == 27) this.meta.bank_account += ' ';
        }
      },
    },
    methods: {
      save: function () {
        axios({
          url: this.$store.state.url + '/api/customer/update-data',
          data: {
            token: this.$store.state.token,
            meta: this.meta,
          },
          method: 'POST',
        })
          .then(resp => {
            this.message.content = resp.data.message;

            if (resp.data.type) this.message.type = resp.data.type;
          })
          .catch(() => {});
      },
      getMeta: function () {
        axios({
          url: this.$store.state.url + '/api/customer/get-data',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            resp.data.data.forEach(meta => {
              this.meta[meta.meta_name] = meta.meta_value;
            });
          })
          .catch(() => {});
      },
    },
    mounted() {
      this.getMeta();
    },
  };
</script>
